.formbook {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-top: 20px;
  
}

.formbook .input {
    width: 100%;
    background: white;
    border: none;
    padding: 15px 20px;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: #223457 0px 10px 10px -5px;
    border-inline: 2px solid transparent;
  }
