.veh {
  border: none;
  padding: 10px;
  border-radius: 30px;
  padding-bottom: 25px;
  background: #f0efef;
  box-shadow: #becdd4 5px 10px 10px -5px;
  border-inline: 2px solid transparent;
  margin-top: 30px;
}

.form .inputs {
  width: 100%;
  background: white;
  border: none;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #cff0ff 0px 10px 10px -5px;
  border-inline: 2px solid transparent;
}

.form .inputs::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .inputs::placeholder {
  color: rgb(170, 170, 170);
}

.form .inputs:focus {
  outline: none;
  border-inline: 2px solid #12b1d1;
}

/* liste des véhicules */
.listveh {
  border: none;
  padding: 20px;
  border-radius: 30px;
  margin-top: 30px;
  background: #f0efef;
  box-shadow: #becdd4 5px 10px 10px -5px;
  border-inline: 2px solid transparent;
}

.tabveh {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.tabveh th,
.tabveh td {
  padding: 8px 12px;
  border: 1px solid #dee2e6;
}

.tabveh th {
  background-color: #f8f9fa;
  text-transform: uppercase;
  font-weight: 600;
}

.tabveh td {
  vertical-align: middle;
}

.btn-group .btn {
  margin: 2px;
}

.btn-group .btn-sm {
  padding: 4px 8px;
  font-size: 14px;
}

.btn-group .btn i {
  margin-right: 0;
}

@media (max-width: 768px) {
  .tabveh th, .tabveh td {
    font-size: 14px;
    padding: 6px 8px;
  }

  .btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-group .btn {
    flex: 1 1 30%;
  }
}
