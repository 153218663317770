.cont {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90%;
  background: #f8f9fd;
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: #9faf13;
  margin: 20px;
  background-color: transparent;
}

.heading {
  text-align: center;
  font-weight: 900;
  font-size: 30px;
  color: rgb(255, 255, 255);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.form .input {
  width: 100%;
  background: rgb(255, 255, 255);
  border: none;
  color: black;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  box-shadow: #9faf13;
  border-inline: 2px solid transparent;
}

.form .input::-moz-placeholder {
  color: rgb(170, 170, 170);
}

.form .input::placeholder {
  color: rgb(170, 170, 170);
}

.form .input:focus {
  outline: none;
  border-inline: 2px solid #9faf13;
}

.form .forgot-password {
  display: block;
  margin-top: 10px;
  margin-left: 10px;
}

.form .forgot-password a {
  font-size: 11px;
  color: #0099ff;
  text-decoration: none;
}

.form .login-button {
  display: block;
  width: 100%;
  font-weight: bold;
  background: #9faf13;
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: rgba(194, 236, 128, 0.878) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.form .login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 23px 10px -20px;
}

.form .login-button:active {
  transform: scale(0.95);
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 15px 10px -10px;
}

.social-account-container {
  margin-top: 25px;
}

.social-account-container .title {
  display: block;
  text-align: center;
  font-size: 10px;
  color: rgb(170, 170, 170);
}

.social-account-container .social-accounts {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-account-container .social-accounts .social-button {
  background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
  border: 5px solid white;
  padding: 5px;
  border-radius: 50%;
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  place-content: center;
  box-shadow: rgba(133, 189, 215, 0.8784313725) 0px 12px 10px -8px;
  transition: all 0.2s ease-in-out;
}

.social-account-container .social-accounts .social-button .svg {
  fill: white;
  margin: auto;
}

.social-account-container .social-accounts .social-button:hover {
  transform: scale(1.2);
}

.social-account-container .social-accounts .social-button:active {
  transform: scale(0.9);
}

.agreement {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.agreement a {
  text-decoration: none;
  color: #0099ff;
  font-size: 9px;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/expedier-drydock.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 10;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.covertitle {
  position: absolute;
  background-position: center;
  top: 10%;
  font-size: 10vh;
  color: white;
  text-align: center;
  z-index: 0;
  opacity: 1;
}

@media (max-width: 768px) {
  .cont {
    padding: 20px;
  }

  .heading {
    font-size: 24px;
  }

  .form .input {
    padding: 12px 15px;
    margin-top: 12px;
  }

  .form .login-button {
    padding-block: 12px;
    margin: 15px auto;
  }

  .social-account-container {
    margin-top: 20px;
  }

  .social-account-container .title {
    font-size: 9px;
  }

  .social-account-container .social-accounts .social-button {
    width: 35px;
  }

  .agreement a {
    font-size: 8px;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 20px;
  }

  .form .input {
    padding: 10px 12px;
    margin-top: 10px;
  }

  .form .login-button {
    padding-block: 10px;
    margin: 10px auto;
  }

  .social-account-container .social-accounts .social-button {
    width: 30px;
  }

  .agreement a {
    font-size: 7px;
  }
}

.social-account-container .title a {
  font-size: 1.2rem; /* Augmenter la taille de la police */
  background-color: #9faf13;
  text-decoration: none; /* Supprimer la sous-ligne */
  padding: 10px 20px; /* Ajouter du padding pour rendre le lien plus gros */
  border-radius: 5px; /* Arrondir les bords */
  transition: background-color 0.3s ease, color 0.3s ease; /* Ajouter une transition pour les effets hover */
  display: inline-block; /* S'assurer que le lien se comporte comme un bouton */
}

.social-account-container .title a:hover {
  background-color: #9faf13; /* Changer la couleur de fond au survol */
  color: white; /* Changer la couleur du texte au survol */
}

/* bouton de retour */

.back-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button i {
  margin-right: 8px;
}

.back-button:hover {
  background-color: #45a049;
}
