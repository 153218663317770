.tabpalette th,
.tabpalette td {
  padding: 8px 12px;
  border: 1px solid #dee2e6;
}

.tabpalette th {
  background-color: #f8f9fa;
  text-transform: uppercase;
  font-weight: 600;
}

.tabpalette td {
  vertical-align: middle;
}

.btn-group .btn {
  margin: 2px;
}

.btn-group .btn-sm {
  padding: 4px 8px;
  font-size: 14px;
}

.btn-group .btn i {
  margin-right: 0;
}

@media (max-width: 768px) {
  .tabpalette th, .tabpalette td {
    font-size: 14px;
    padding: 6px 8px;
  }

  .btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-group .btn {
    flex: 1 1 30%;
  }
}