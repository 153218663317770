.sidebar {
  display: flex;
  flex-direction: column;
  height: 242vh;
  justify-content: space-between;
  color: #fff;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5px;
}
.logo-text{
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  border: 1px solid none;
  padding: 10px;
  border-radius: 100%;
  background-color: rgb(179, 206, 58);
}
.logo-icon {
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
}

.menu-bar {
  /* height: auto; */
  flex-grow: 1; /*Prend tous l'espace disponible*/
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 1rem;
  overflow-y: auto;
}

.toggle-theme-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.toggle {
  margin-left: 15px;
}
